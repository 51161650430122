import React from "react"

import { Layout } from "../components"

export default function Example() {
  return (
    <Layout>
      <div className="min-h-screen flex items-center justify-center bg-white dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-4">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">There was an issue with your contact request</h2>
            <p className="mt-6 text-center text-xl text-gray-900 dark:text-white">Please try again later or email us directly at <a href = "mailto:contact@shineco.org">contact@shineco.org</a>.</p>
          </div>
          <a
            href="/"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
          >
            Return to the Home page
          </a>
        </div>
      </div>
    </Layout>
  )
}